import { isMatch, isValid, parse, parseISO } from 'date-fns'

export default function parseDate(dateString: string | undefined) {
  try {
    if (
      dateString === '' ||
      dateString?.toLowerCase() === 'null' ||
      !dateString
    ) {
      return null
    }

    if (isMatch(dateString, 'yyyy-MM-dd')) {
      return parse(dateString, 'yyyy-MM-dd', new Date())
    }

    // format date string from snowflake into proper ISO form
    dateString = dateString
      .replace(/([+-]\d{2})(\d{2})$/, '$1:$2')
      .replace(' ', 'T')
      .replace(' ', '')
    const parsedDate = parseISO(dateString)

    if (!isValid(parsedDate)) {
      console.log('could not parse date', dateString)
      return null
    }

    return parsedDate
  } catch (error) {
    console.log('failed to parse date', dateString)
    console.error(error)
    return null
  }
}
