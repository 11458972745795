import { format, getYear } from 'date-fns'
import parseDate from './parseDate'

export default function formatDate(dateString: string) {
  const date = parseDate(dateString)

  if (!date) {
    return '-'
  }
  if (getYear(date) === 9999) {
    return 'never'
  }

  try {
    return format(date, 'MMM dd, yyyy')
  } catch (error) {
    console.log('failed to format date', dateString, date)
    console.error(error)
    return '-'
  }
}
